import axios from "./axios";

export const getPersonalInfo = async function (t) {
  return await axios.get("/v1/user/getPersonalInfo", {
    withCredentials: true,
    credentials: "include",
    headers: {
      'Authorization': `Bearer ${t.accessToken}`,
      'Content-Type': 'application/json',
    },
  });
};
export const getPersonalSecurity = async function (t) {
  if(t === null){
    return await axios.post(
      "/v1/user/getPersonalSecurity",
      {
        nothing: "nothing",
      },
      {
        withCredentials: true,
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }
  else{
    return await axios.post(
      "/v1/user/getPersonalSecurity",
      {
        nothing: "nothing",
      },
      {
        withCredentials: true,
        credentials: "include",
        headers: {
          'Authorization': `Bearer ${t.accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
  }
};
