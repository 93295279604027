import axios from "./axios";

export const getAllPublishedAuthors = async function (t) {
  return await axios.post(
    "/v1/publishing/getAllPublishedAuthors",
    {
      nothing: "nothing",
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const getAllFeatured = async function (t) {
  return await axios.post(
    "/v1/publishing/getAllFeaturedPub",
    {
      nothing: "nothing",
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const getAllComingSoon = async function (t) {
  return await axios.get(
    "/v1/publishing/getAllComingSoon",
    {
      nothing: "nothing",
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const getAllPublishedSeries = async function (t) {
  return await axios.post(
    "/v1/publishing/getAllPublishedSeries",
    {
      nothing: "nothing",
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const getAllPublishedBooks = async function (t) {
  return await axios.post(
    "/v1/publishing/getAllPublishedBooks",
    {
      nothing: "nothing",
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const getAllPublishedComics = async function (t) {
  return await axios.post(
    "/v1/publishing/getAllPublishedComics",
    {
      nothing: "nothing",
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const deletePublishedBook = async function (id, position, t) {
  return await axios.post(
    "/v1/publishing/deletePublishedBook",
    {
      id: id,
      position: position,
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const featurePublishedRemove = async function (id, position, t) {
  return await axios.post(
    "/v1/publishing/featurePublishedRemove",
    {
      id: id,
      position: position,
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const featurePublished = async function (id, position, t) {
  return await axios.post(
    "/v1/publishing/featurePublished",
    {
      id: id,
      position: position,
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const deletePublishedComic = async function (id, t) {
  return await axios.post(
    "/v1/publishing/deletePublishedComic",
    {
      id: id,
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const deletePublishedSeries = async function (id, t) {
  return await axios.post(
    "/v1/publishing/deletePublishedSerie",
    {
      id: id,
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const deletePublishedAuthor = async function (id, t) {
  return await axios.post(
    "/v1/publishing/deletePublishedAuthor",
    {
      id: id,
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const newPublishedSeries = async function (data, t) {
  return await axios.post(
    "/v1/publishing/newPublishedSeries",
    { data: data },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const newPublishedAuthor = async function (data, t) {
  return await axios.post(
    "/v1/publishing/newPublishedAuthor",
    { data: data },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const newPublishedBook = async function (data, t) {
  return await axios.post(
    "/v1/publishing/newPublishedBook",
    { data: data },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const updatePublishedBook = async function (data, id, position, t) {
  return await axios.post(
    "/v1/publishing/updatePublishedBook",
    {
      data: data,
      id: id,
      position: position,
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const updatePublishedAuthor = async function (data, id, t) {
  return await axios.post(
    "/v1/publishing/updatePublishedAuthor",
    {
      data: data,
      id: id,
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const updatePublishedSeries = async function (data, id, t) {
  return await axios.post(
    "/v1/publishing/updatePublishedSeries",
    {
      data: data,
      id: id,
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getAllGenres = async function (t) {
  return await axios.post(
    "/v1/publishing/getAllGenres",
    {
      nothing: "nothing",
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const getAllNews = async function (t) {
  return await axios.post(
    "/v1/publishing/getAllNews",
    {
      nothing: "nothing",
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteNews = async function (id, t) {
  return await axios.post(
    "/v1/publishing/newsDelete",
    {
      id: id,
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const newNews = async function (data, t) {
  return await axios.post(
    "/v1/publishing/newNews",
    {
      data: data,
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateNews = async function (data, id, t) {
  return await axios.post(
    "/v1/publishing/updateNews",
    {
      data: data,
      id: id,
    },
    {
      withCredentials: true,
      credentials: "include",
      headers: {
        Authorization: `Bearer ${t.accessToken}`,
        "Content-Type": "application/json",
      },
    }
  );
};
