import * as ActionTypes from "../actions";

//Handling auth user state

const initialState = {
  isLoggedIn: !!localStorage.getItem("token"),
  token: localStorage.getItem("token"),
  accessToken: "",
  isMOD: null,
  currentUser: {
    name: "admin"
  },
};

const reducer = (state = initialState, action) => {
  if (action.type === ActionTypes.LOGIN_USER) {
    return {
      ...state,
      isLoggedIn: true,
      token: action.token,
      accessToken: action.token,
      currentUser: {
        name: "admin"
      },
    };
  }
  if (action.type === ActionTypes.SET_MOD) {
    return {
      ...state,
      isMOD: action.isMOD
    };
  }


  if (action.type === ActionTypes.LOGOUT_USER) {
    localStorage.removeItem("token");
    return {
      ...state,
      isLoggedIn: false,
      token: null,
      accessToken: null,
      currentUser: {
        email: null,
        picture: null,
      },
    };
  }
  if (action.type === ActionTypes.LOAD_USER) {
    return {
      ...state,
      currentUser: action.currentUser,
    };
  }

  return { ...state };
};

export default reducer;
