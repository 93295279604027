import React, { useEffect } from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { getPersonalSecurity } from "../controller/profileController";
import { toast } from "react-toastify";
import * as ActionTypes from "../redux/actions";

const Dashboard = ({setMOD,userLogin}) => {
  const [t] = useTranslation();
  const getSecurity = async () => {
    try {
      console.log(userLogin);
      const res = await getPersonalSecurity(userLogin);
      if (res.data.success === true) {
        setMOD(res.data.data);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
          "getProfileSecurity"
      );
    }
  };

  useEffect(() => {
    getSecurity();
  }, []);

  return (
    <>
      <Grid container mt={2} spacing={2}>
        hola
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => ({
  userLogin: state.auth.token,
});
const mapDispatchToProps = (dispatch) => ({
  setMOD: (isMOD) => dispatch({ type: ActionTypes.SET_MOD,isMOD })
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
